body[data-theme="light"] {
  @import "../light-slider.scss";

  .navbar-b.navbar-trans .nav-link,
  .navbar-b.navbar-reduce .nav-link {
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
  }
  .navbar-b.navbar-trans .nav-link.active:before {
    background-color: #000;
  }
}
