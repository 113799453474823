@import "../src/scss/themes/theme-dark.scss";
@import "../src/scss/themes/theme-light.scss";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap");

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
NAVBAR
*/

.navbar-b {
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-top: 1.563rem;
  padding-bottom: 1.563rem;
}

.navbar-b.navbar-reduce {
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}

.navbar-b.navbar-trans .nav-item,
.navbar-b.navbar-reduce .nav-item {
  position: relative;
  padding-right: 10px;
  padding-left: 0;
}

.navbar-b.navbar-trans .nav-link,
.navbar-b.navbar-reduce .nav-link {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  // border: 1px solid PINK;
}

.navbar-b.navbar-trans .nav-link:before,
.navbar-b.navbar-reduce .nav-link:before {
  content: "";
  position: absolute;
  margin-left: 0px;
  width: 0%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  transition: all 500ms ease;
}

.navbar-b.navbar-trans .nav-link:hover,
.navbar-b.navbar-reduce .nav-link:hover {
  color: #1b1b1b;
}

.navbar-b.navbar-trans .nav-link:hover:before,
.navbar-b.navbar-reduce .nav-link:hover:before {
  width: 35px;
}

.navbar-b.navbar-trans .show > .nav-link:before,
.navbar-b.navbar-trans .active > .nav-link:before,
.navbar-b.navbar-trans .nav-link.show:before,
.navbar-b.navbar-trans .nav-link.active:before,
.navbar-b.navbar-reduce .show > .nav-link:before,
.navbar-b.navbar-reduce .active > .nav-link:before,
.navbar-b.navbar-reduce .nav-link.show:before,
.navbar-b.navbar-reduce .nav-link.active:before {
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.navbar-b.navbar-trans .nav-link.active:before {
  background-color: #fff;
}

.navbar-b.navbar-trans .nav-link:hover {
  color: #fff;
}

.navbar-b.navbar-trans .show > .nav-link,
.navbar-b.navbar-trans .active > .nav-link,
.navbar-b.navbar-trans .nav-link.show,
.navbar-b.navbar-trans .nav-link.active {
  color: #fff;
}

.navbar-b.navbar-reduce {
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-b.navbar-reduce .nav-link {
  color: #0078ff;
}

.navbar-b.navbar-reduce .nav-link:before {
  background-color: #0078ff;
}

.navbar-b.navbar-reduce .nav-link:hover {
  color: #0078ff;
}

.navbar-b.navbar-reduce .show > .nav-link,
.navbar-b.navbar-reduce .active > .nav-link,
.navbar-b.navbar-reduce .nav-link.show,
.navbar-b.navbar-reduce .nav-link.active {
  color: #0078ff;
}

.navbar-b.navbar-reduce .navbar-brand {
  color: #0078ff;
}

.navbar-b.navbar-reduce .navbar-toggler span {
  background-color: #1b1b1b;
}

.navbar-b .navbar-brand {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
}

.navbar-b .navbar-nav .dropdown-item.show .dropdown-menu,
.navbar-b .dropdown.show .dropdown-menu,
.navbar-b .dropdown-btn.show .dropdown-menu {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  visibility: visible !important;
}

.navbar-b .dropdown-menu {
  margin: 1.12rem 0 0;
  border-radius: 0;
}

.navbar-b .dropdown-menu .dropdown-item {
  padding: 0.7rem 1.7rem;
  transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item:hover {
  background-color: #0078ff;
  color: #fff;
  transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item.active {
  background-color: #0078ff;
}

/* Hamburger Navbar */

.navbar-toggler {
  position: relative;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span {
  display: block;
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: -webkit-transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

.title-container {
  height: 50px;
}

.title-styles {
  font-family: "Raleway", sans-serif;
  font-size: 250%;
}

.header-icon {
  height: 150px;
  width: 150px;
}

header {
  position: relative;
  height: 520px;
  min-height: 450px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  text-align: center;
  overflow: hidden;
  background-color: #e9d5a1;
  animation: backgorundcolor 1s linear;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 0;
}

.language-icon {
  font-size: 50px;
  cursor: pointer;
}

.project-date {
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 8px 15px 2px 15px;
  position: relative;
  z-index: -500;
  border-top: 5px solid #696969;
  border-radius: 0 0 5px 5px;
  background-color: #696969;
  color: white;
}

.skills-tile {
  background-color: transparentize(#f8f5f4, 0.95);
  padding: 10px 10px 5px 10px;
  width: 100px;
  margin: 5px 0 5px 0;
  border-radius: 8px;
}

.language {
  font-size: 25px;
  background-color: #e9d5a1;
  padding-bottom: 10px;
  padding-top: 80px;
}

header h1 {
  font-size: 400%;
  text-align: center;
  font-weight: 600 !important;
  color: #353239;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin: 0 auto 18px auto;
  width: 100%;
}

.slider-image {
  border: 5px solid #d7caaa;
}

.slider-tab {
  background-color: #d7caaa;
  height: 25px;
}

.slider-iconfiy {
  margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
  cursor: none !important;
  display: none;
}

#about {
  background: #fff;
  overflow: hidden;
}

#about h1 {
  padding-top: 5%;
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: black;
  text-align: center;
}

.foto img {
  display: block;
  width: 100%;
}

.foto div {
  background: #ffffff;
  display: inline-block;
  margin: 0 auto 5% auto;
  padding: 10px 10px 5px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 0;
  position: relative;
}

.foto div:after {
  color: #333;
  font-size: 25px;
  content: attr(title);
  position: relative;
  top: 15px;
}

.foto div:hover {
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  transform: scale(1.01);
  z-index: 10;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
}

.project-title-settings {
  margin-top: 5%;
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: black;
  text-align: center;
}

.polaroid img {
  display: block;
}

.polaroid span {
  background: #ffffff;
  display: inline-block;
  // margin: 55px 0;
  padding: 15px 15px 30px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 0;
  position: relative;
}

.link-href {
  color: black;
}

.wave {
  font-size: 160%;
}

.font-trebuchet {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

#preview {
  width: 500px;
  background-color: #ebeaf5;
  padding: 15px;
  position: relative;
  margin-bottom: 15%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#resume {
  background: #efe1bd;
}

.experience-icon {
  font-size: 300%;
  margin-top: 25%;
  text-align: center;
}

.main-badge {
  font-size: 13px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #ae944f !important;
  color: white;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.experience-badge {
  font-size: 11px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #f9f5e9 !important;
  color: black;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.vertical-timeline-element-date {
  padding: 0 !important;
  margin: 0 !important;
}

@media only screen and (max-width: 1170px) {
  .experience-icon {
    font-size: 170%;
    margin-top: 27%;
    text-align: center;
  }
}

.modal-inside .modal-content {
  background: white;
}

.bars {
  width: 95%;
  float: left;
  padding: 0;
  text-align: left;
}

.bars .skills {
  margin-top: 36px;
  list-style: none;
}

.bars li {
  position: relative;
  margin-bottom: 60px;
  background: #ccc;
  height: 42px;
  border-radius: 3px;
}

.bars li em {
  font: 15px "opensans-bold", sans-serif;
  color: #313131;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  position: relative;
  top: -36px;
}

.bar-expand {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding-right: 24px;
  background: #313131;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 3px 0 0 3px;
}

.modal-close {
  text-align: right;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
}

.close-icon {
  color: black;
  font-weight: lighter !important;
}

.modal-description {
  text-align: justify;
  padding: 5px 5px 0 5px;
  margin-bottom: 20px;
  font-size: 12px;
}

.awssld__next {
  outline: none !important;
}

.awssld__prev {
  outline: none !important;
}

.loader-bar-color {
  color: black !important;
}

#portfolio {
  background: #efe1bd;
}

.portfolio-item {
  max-width: 100%;
  text-align: center;
}

.portfolio .portfolio-item .portfolio-item-caption {
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
  background-color: rgba(51, 51, 51, 0.9);
}

.portfolio .portfolio-item .portfolio-item-caption:hover {
  opacity: 1;
}

.portfolio
  .portfolio-item
  .portfolio-item-caption
  .portfolio-item-caption-content {
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  .portfolio .closeButtonResponsive {
    display: block;
  }
  // .portfolio .portfolio-item {
  //   margin-bottom: 30px;
  // }

  .navbar-b.navbar-trans .nav-item,
  .navbar-b.navbar-reduce .nav-item {
    padding-left: 10px;
  }
}

@media (max-width: 450px) {
  .portfolio .closeButtonResponsive {
    display: block;
  }
  // .portfolio .portfolio-item {
  //   margin-bottom: 30px;
  // }

  .navbar-b.navbar-trans .nav-item,
  .navbar-b.navbar-reduce .nav-item {
    padding-left: 10px;
  }
  .navbar-b.navbar-reduce .nav-link.show:before,
  .navbar-b.navbar-reduce .nav-link.active:before {
    bottom: 50%;
    left: 55%;
  }
}

#skills {
  background: #1f1f1f;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 10%;
}

.section-title {
  padding-top: 5%;
  padding-bottom: 5%;
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  text-align: center;
}

.skill-icon {
  font-size: 180%;
  color: white;
  text-align: center;
  position: relative;
}

footer {
  background: #1f1f1f;
  min-height: 100px;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  color: white;
  position: relative;
  text-align: center;
}

footer a,
footer a:visited {
  color: #fff;
}

footer a:hover,
footer a:focus {
  color: #fff;
}

.social-links {
  margin-top: 50px;
  font-size: 22px;
}
